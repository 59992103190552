import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Engineer",
          "Expertise in ",
          "ReactJS",
          "Microsoft Power Automate",
          "Microsoft Power Apps",
          "JavaScript",
          "Java",
          "Selenium",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
