import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineWhatsApp} from "react-icons/ai"
import { IoMdMail} from "react-icons/io";
import { FaLinkedinIn,FaTelegram } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by Sri Manikandan S</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} SMK</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
          <li className="social-icons">
                <a
                  href="https://wa.link/tyd7am"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <AiOutlineWhatsApp/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:mailformesrimanikandan@gmail.com"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <IoMdMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sri-manikandan-9974b7250/"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://telegram.me/mailformesrimanikandan"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <FaTelegram />
                </a>
              </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
