import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Analysis from "../../Assets/Analysis.png";
import WebsiteAutomation from "../../Assets/WebsiteAutomation.png";
import mobileDevelopement from "../../Assets/Mobiledevelopment.png";
import Card from "react-bootstrap/Card";
import softwaretester from "../../Assets/softwaretester.png";
import ActiveSupport from "../../Assets/ActiveSupport.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={softwaretester} alt="card-img" style={{maxHeight:"360px"}}/>
              <Card.Body>
                <Card.Title><b>Testing Automation Engineer</b></Card.Title>
                <Card.Text><span className="purple">Hcl Tech, Chennai</span> <i>(Jul 2023 - Current)</i></Card.Text>
                <Card.Text style={{textAlign:"left"}}>My Contribution to the Project:</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                  <ul>
                    <li> <span className="purple">Framework Development: </span>Built a testing automation framework for iAssure using MITM Proxy with HeadSpin and Appium to capture and validate mobile app network logs.</li>
                    <li> <span className="purple">Mobile App Testing: </span>Automated mobile testing with Appium, ensuring accurate network log validation.</li>
                    <li> <span className="purple">Web Automation: </span>Developed web automation test cases with Selenium, using DevTools to capture and validate network logs.</li>
                    <li> <span className="purple">Collaboration and Documentation: </span>Collaborated with development teams and provided detailed documentation for framework integration and maintenance.</li>
                  </ul>
                </Card.Text>
                {"\n"}
                {"\n"}
                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="project-card">
          <Card className="project-card-view">
              <Card.Img variant="top" src={ActiveSupport} alt="card-img" style={{maxHeight:"360px"}} />
              <Card.Body>
                <Card.Title><b>React Production Support Engineer</b></Card.Title>
                <Card.Text><span className="purple">Hcl Tech, Chennai</span> <i>(Jul 2022 - Jul 2023)</i></Card.Text>
                <Card.Text style={{textAlign:"left"}}>My Contribution to the Project:</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                  <ul>
                    <li> <span className="purple">Incident Management: </span>Resolved production defects, minimizing user disruption by addressing root causes in real-time.</li>
                    <li> <span className="purple">Bug Fixing: </span>Debugged and fixed React and JavaScript issues, improving stability and performance.</li>
                    <li> <span className="purple">Performance Tuning: </span>Optimized React components and front-end code for enhanced production efficiency.</li>
                    <li> <span className="purple">Cross-Functional Collaboration: </span>  Collaborated with developers, testers, and stakeholders to diagnose issues and deploy updates.</li>
                   </ul>
                </Card.Text>
                {"\n"}
                {"\n"}
                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
              </Card.Body>
            </Card>
          </Col>
          
          <Col md={4} className="project-card">
          <Card className="project-card-view">
              <Card.Img variant="top" src={mobileDevelopement} alt="card-img" style={{maxHeight:"360px"}} />
              <Card.Body>
                <Card.Title><b>Power Apps Developer</b></Card.Title>
                <Card.Text><span className="purple">Hcl Tech, Chennai</span> <i>(Mar 2022 - Jul 2022)</i></Card.Text>
                <Card.Text style={{textAlign:"left"}}>My Contribution to the Project:</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                  <ul>
                    <li> <span className="purple">App Development: </span> Developed the Demand Master app using Power Apps to streamline candidate profile access and shortlisting.</li>
                    <li> <span className="purple">User Experience: </span> Designed an intuitive interface for quick navigation and efficient use by interviewers.</li>
                    <li> <span className="purple">SharePoint Integration: </span>Integrated with SharePoint for real-time candidate data and profile accuracy</li>
                    </ul>
                </Card.Text>
                {"\n"}
                {"\n"}
                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
          <Card className="project-card-view">
              <Card.Img variant="top" src={Analysis} alt="card-img" style={{maxHeight:"360px"}} />
              <Card.Body>
                <Card.Title><b>Customer Journey Analyst</b></Card.Title>
                <Card.Text><span className="purple">Hcl Tech, Chennai</span> <i>(Jan 2022 - Mar 2022)</i></Card.Text>
                <Card.Text style={{textAlign:"left"}}>My Contribution to the Project:</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                  <ul>
                    <li> <span className="purple">Customer Journey Mapping: </span> Collaborated with an architect to document the full customer journey and key touchpoints.</li>
                    <li> <span className="purple">User Experience Analysis: </span>Identified areas for improvement, enhancing user experience and satisfaction.</li>
                    <li> <span className="purple">Documentation Support: </span>Assisted in creating detailed documentation for stakeholders and implementation teams.</li>
                    <li> <span className="purple">Process Improvement: </span>Suggested and implemented changes to optimize customer engagement and streamline the experience.</li>
                    </ul>
                </Card.Text>
                {"\n"}
                {"\n"}
                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
          <Card className="project-card-view">
              <Card.Img variant="top" src={WebsiteAutomation} alt="card-img" style={{maxHeight:"360px"}} />
              <Card.Body>
                <Card.Title><b>Automation Specialist</b></Card.Title>
                <Card.Text><span className="purple">Hcl Tech, Chennai</span> <i>(Aug 2021 - Jan 2022)</i></Card.Text>
                <Card.Text style={{textAlign:"left"}}>My Contribution to the Project:</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                  <ul>
                    <li> <span className="purple">Process Automation: </span> Automated candidate shortlisting in Project Keystone using Power Automate to move resumes in SharePoint based on interviewer updates.</li>
                    <li> <span className="purple">Workflow Optimization: </span>Reduced manual effort, speeding up resume handling and improving efficiency.</li>
                    <li> <span className="purple">Power Automate Integration: </span>Created custom workflows for seamless SharePoint integration and enhanced accuracy.</li>
                    <li> <span className="purple">Collaboration: </span>Worked with HR to tailor the automation to their needs.</li>
                    <li> <span className="purple">Documentation: </span>Provided detailed documentation, including workflow diagrams and user guides for maintenance and training.</li>
                    </ul>
                </Card.Text>
                {"\n"}
                {"\n"}
                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
