import React from "react";
import { Col, Row } from "react-bootstrap";
import Headspin from "../../Assets/logo-headspin.png";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiMicrosoftsharepoint ,
  SiIntellijidea 
} from "react-icons/si";
import { FaJira,FaSourcetree,FaGitlab  } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
       <Tooltip title="Visual Studio Code">
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      </Tooltip>
      <Tooltip title="Intellij">
        <Col xs={4} md={2} className="tech-icons">
          <SiIntellijidea/>
        </Col>
      </Tooltip>
      <Tooltip title="Postman">
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      </Tooltip>
      <Tooltip title="Slack">
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
      </Col>
      </Tooltip>
      <Tooltip title="Jira">
      <Col xs={4} md={2} className="tech-icons">
        <FaJira />
      </Col>
      </Tooltip>
      <Tooltip title="Headspin">
        <Col xs={4} md={2} className="tech-icons">
          <img src={Headspin} style={{ width: "80px", height: "80px" }}></img>
        </Col>
      </Tooltip>
      <Tooltip title="SharePoint">
        <Col xs={4} md={2} className="tech-icons">
          <SiMicrosoftsharepoint/>
        </Col>
      </Tooltip>
    
      <Tooltip title="SourceTree">
        <Col xs={4} md={2} className="tech-icons">
        <FaSourcetree />
        </Col>
      </Tooltip>
      <Tooltip title="Gitlab">
        <Col xs={4} md={2} className="tech-icons">
        <FaGitlab />
        </Col>
      </Tooltip>
    </Row>
  );
}

export default Toolstack;
