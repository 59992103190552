import React from "react";
import { Col, Row } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DiJavascript1, DiReact, DiJava } from "react-icons/di";
import { SiPowerautomate, SiPowerapps, SiSelenium } from "react-icons/si";
import mitm from "../../Assets/mitm-logo512.png";
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Tooltip title="ReactJs">
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>
      </Tooltip>
      <Tooltip title="Power Automate">
        <Col xs={4} md={2} className="tech-icons">
          <SiPowerautomate />
        </Col>
      </Tooltip>
      <Tooltip title="Power Apps">
        <Col xs={4} md={2} className="tech-icons">
          <SiPowerapps />
        </Col>
      </Tooltip>
      <Tooltip title="Selenium">
        <Col xs={4} md={2} className="tech-icons">
          <SiSelenium />
        </Col>
      </Tooltip>
  
      <Tooltip title="Mitm Proxy">
        <Col xs={4} md={2} className="tech-icons">
          <img src={mitm} style={{ width: "80px", height: "80px" }}></img>
        </Col>
      </Tooltip>
      <Tooltip title="Java Script">
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 />
        </Col>
      </Tooltip>
      <Tooltip title="Java">
        <Col xs={4} md={2} className="tech-icons">
          <DiJava />
        </Col>
      </Tooltip>
    </Row>
  );
}

export default Techstack;
